@use "../config" as *;
.widget-layout-18 {
    @extend %relative;
    &.waf-component {
        @extend %pt-0;
        @extend %pb-22;
        @extend %mb-20;
    }
    &::before {
        content: "";
        width: var(--window-inner-width);
        height: calc(100% + 8rem);
        @include bgImg("cssimages/pattern/blue-gradient-left.png", top 10% left 0, cover);
        @include position(-6rem, null, null, -1.6rem);
        @extend %zindex-content-bg;
    }
    .layout-wrapper {
        max-width: unset;
    }
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
        }
    }
    .status {
        @extend %d-none;
    }
    .title {
        @extend %mb-10;
        @extend %title-40;
        @include title-with-icon(shape, trophy, 21, neutral-0);
        &::before {
            @extend %neutral-40;
        }
    }
    .image {
        width: 1.6rem;
        height: 1.6rem;
        aspect-ratio: 1/1;
        @extend %circle-radius;
    }
    .head-tab {
        @extend %w-100;
        @include position(null, null, 0, 0);
        li {
            @extend %flex-c-c;
        }
        a {
            width: max-content;
            @extend %btn-fill-text-icon;
            @extend %capitalize;
            @extend %gap-3;
            &::after {
                @include icon(arrow-right, 15);
            }
        }
    }
    .card {
        &-section {
            @extend %mr-2-neg;
        }
        &-list {
            @include card-count(1.1, var(--space-4));
        }
        &-item {
            height: auto;
            min-height: 3.6rem;
            @extend %bg-neutral-0;
            @extend %p-6;
            @extend %border-radius-l;
            @extend %flex-column;
            @include border(1, neutral-20);
            > .card-title {
                @extend %mb-4;
                @include truncate-text(2);
            }
        }
        &-number {
            max-height: 9.6rem;
            @extend %d-block;
            @extend %title-30;
            @extend %mb-2;
            @include truncate-text(2);
        }
        &-title {
            @extend %mb-0;
            @extend %body-lb;
        }
        &-info {
            @extend %flex-n-c;
            @extend %gap-2;
        }
        &-label {
            @extend %neutral-70;
        }
        &-option {
            @extend %flex-n-c;
            @extend %gap-4;
            @extend %mt-4;
            .card-title {
                max-width: 80%;
            }
        }
    }
}
@include mq(col-tablet) {
    .widget-layout-18 {
        &::before {
            background-position: top -11rem left 0;
            top: -53rem;
            left: -1.6rem;
            height: calc(100% + 30rem);
        }
        &.waf-component {
            padding-block: var(--space-16);
            margin-bottom: var(--space-16);
        }
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        .title {
            font-size: 4rem;
            line-height: 4.8rem;
        }
        .head-wrap {
            @include flex-config(flex, null, space-between, center);
        }
        .head-tab {
            position: static;
            width: auto;
            li {
                justify-content: flex-end;
            }
        }
        .card {
            &-section {
                margin-right: 0;
            }
            &-list {
                @include card-count(2, var(--space-4), wrap);
            }
        }
    }
}
@include mq(col-desktop) {
    .widget-layout-18 {
        .card {
            &-list {
                @include card-count(4, var(--space-4), wrap);
            }
        }
    }
}
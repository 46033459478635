@use '../config/' as *;

.waf-sports-inpictures{
    .layout-wrapper{
        max-width: unset;
        @extend %py-0;
        @extend %mb-20;
    }
    .title{
        max-width: var(--container-max-width);
        margin-inline: auto;
        @extend %title-40;
        @extend %neutral-70;
        @extend %mb-10;
    }
    .article{
        &-gallery{
            &-list {
                flex-wrap: wrap;
                @extend %mx-2-neg;
                @extend %gap-4;
                @extend %flex-n-fs;
            }
            &-item {
                @extend %hidden;
                @extend %border-radius-s;
                &:nth-child(1), &:nth-child(6) {
                    aspect-ratio: 4/3;
                    @extend %w-100;
                }
                &:nth-child(2), &:nth-child(7){
                    aspect-ratio: 3/4;
                    width: calc(50% - 0.8rem);
                }
                &:nth-child(3), &:nth-child(8){
                    aspect-ratio: 1/1;
                    width: calc(50% - 0.8rem);
                }
                &:nth-child(4){
                    aspect-ratio: 3/4;
                    width: calc(49% - 0.8rem);
                }
                &:nth-child(5){
                    aspect-ratio: 3/4;
                    width: calc(51% - 0.8rem);
                }
            }
        }
    }
    .image{
        object-fit: cover;
        @extend %w-100;
        @extend %h-100;
    }
}

@include mq(col-tablet){
    .waf-sports-inpictures{
        .title{
            font-size: 3.2rem;
            margin-bottom: var(--space-18);
        }
        .article{
            &-gallery{
                &-list{
                    gap: var(--space-6);
                }
                &-item{
                    border-radius: 1.6rem;
                    &:nth-child(1){
                        order: 1;
                    }
                    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(7), &:nth-child(8){
                        width: calc(20% - 1.8rem);
                    }
                    &:nth-child(1), &:nth-child(6) {
                        aspect-ratio: 6/4;
                        width: calc(40% - 1.8rem);
                    }
                    &:nth-child(2){
                        order: 5;
                    }
                    &:nth-child(3){
                        order: 6;
                    }
                    &:nth-child(4){
                        order: 2;
                    }
                    &:nth-child(5){
                        order: 3;
                    }
                    &:nth-child(6){
                        order: 8;
                    }
                    &:nth-child(7){
                        order: 7;
                    }
                    &:nth-child(8){
                        order: 4;
                    }
                    &:nth-child(2), &:nth-child(4), &:nth-child(5), &:nth-child(7){
                        aspect-ratio: 3/4.15;
                    }
                }
            }
        }
    }
}
@use '../config/' as *;
.waf-sports-details {
    margin-top: calc(-1 * var(--space-18));
    @extend %common-sports-table;
    @extend %relative;
    @extend %zindex-youtube-iframe;
    &.waf-component {
        @extend %p-0;
        @extend %mb-20;
    }
    > .layout-wrapper {
        @extend %px-4;
        @extend %py-6;
        @extend %border-radius-m;
        @extend %bg-neutral-0;
        .title {
            @extend %d-block;
        }
    }
    .layout-wrapper {
        > .title {
            @extend %neutral-100;
            @extend %mb-6;
        }
    }
    .waf-info-cards {
        @extend %mt-8;
    }
    .embed-responsive {
        @extend %mb-10;
        @extend %border-radius-s;
    }
    .text {
        @extend %body-l;
        @extend %neutral-70;
        @extend %mb-6;
        &:last-child {
            @extend %mb-0;
        }
    }
    .m-0 {
        margin-bottom: 0;
    }
    .sports-details {
        &-title {
            @extend %title-50;
            @extend %mb-8;
        }
    }
}
@include mq(col-tablet) {
    .waf-sports-details {
        margin-top: -13.4rem;
        .table {
            width: auto;
            min-width: unset;
            margin-left: var(--space-6);
        }
        > .layout-wrapper {
            padding: var(--space-10);
            border-radius: 3.2rem;
        }
        .sports-details {
            &-wrap {
                @include grid(2, var(--space-10));
            }
            &-title {
                grid-area: 2/1/auto/2;
                padding-left: var(--space-6);
            }
            &-text {
                grid-area: 2/2/5/2;
                padding-right: var(--space-6);
            }
        }
        .embed-responsive {
            border-radius: 1.6rem;
            grid-area: 1/span 2;
            margin-bottom: 0;
            &.embed-responsive-9by5::before {
                padding-top: 42.8571428571%;
            }
        }
        .sports-details {
            &-title {
                margin-bottom: 0;
            }
        }
    }
}
@use './config/' as *;
@forward '../scss/main.scss';
@forward './module/common-hero';
@forward '../scss/module/sports-hero';
@forward '../scss/module/sports-inpictures';
@forward '../scss/module/sports-details';
@forward '../scss/events/featured-events';
@forward "./listing/listing-common";
@forward "./listing/multisports-listing";
@forward './events/eventstrip';
@forward '../scss/standings/global-ranking';
@forward '../scss/standings/multisports-ranking';
@forward './module/triathlon-live';
@forward './swiper/swiper-bundle';
@forward './standings/podiums';
.widget-layout-11 {
    .head-tab {
        left: 50%;
        transform: translateX(-50%);
    }
}
.waf-broadcast {
    .tab-container-item {
        &::before {
            background: linear-gradient(268.76deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
        }
    }
    .streaming-content {
        text-align: left;
        .text a {
            color: var(--neutral-20);
            text-decoration: underline;
            font-weight: 700;
        }
    }
}
// .waf-event-strip {
//     .head-tab {
//         a {
//             background-color: var(--neutral-0);
//             color: var(--primary-50);
//         }
//     }
// }
@include mq(col-tablet) {
    .widget-layout-11 {
        .head-tab {
            transform: unset;
        }
    }
}
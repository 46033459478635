@use '../config/' as *;
.widget-layout-11 {
    @extend %relative;
    &.waf-component {
        @extend %py-0;
        @extend %mb-6;
    }
    .layout-wrapper {
        background: linear-gradient(269deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
        @extend %border-radius-l;
        @extend %p-6;
    }
    .waf {
        &-body {
            padding-bottom: calc(4.8rem + 4rem);
        }
    }
    .head {
        &-wrap {
            @extend %flex-column-fe-n;
            @extend %h-100;
        }
        &-tab {
            position: absolute;
            bottom: 2.4rem;
        }
        &-logo {
            order: -1;
            max-width: 33.8rem;
            height: 8rem;
            @extend %mx-auto;
            @extend %text-center;
            @extend %mb-10;
            .logo {
                object-fit: contain;
                object-position: left;
                @extend %h-100;
            }
        }
    }
    .title {
        @include truncate-text(1);
        @extend %neutral-0;
        @extend %title-50;
        @extend %mb-4;
    }
    .sub-title {
        // @include truncate-text(3);
        @extend %neutral-20;
        @extend %body-m;
        @extend %mb-6;
    }
    .head-tab {
        a {
            width: max-content;
            border-color: var(--neutral-0);
            justify-content: space-between;
            @extend %btn-outline;
            @extend %neutral-0;
            @extend %gap-2;
            &:after {
                @include icon(arrow-down);
            }
        }
    }
    @extend %ranking-table;
    .table {
        &-wrapper {
            padding: 0;
        }
        &-footer {
            @extend %flex-column;
            @extend %gap-4;
            a {
                justify-content: space-between;
                border: 0.1rem solid var(--neutral-5);
                @include bg(neutral-5, 3);
                @include color(neutral-0);
                @extend %border-radius-s;
                // @extend %primary-50;
                @extend %title-60;
                @extend %w-100;
                @extend %py-4;
                @extend %capitalize;
                &.download {
                    &::after {
                        content: '\e84d';
                    }
                }
                &::after {
                    // line-height: 1.1;
                    line-height: 1;
                }
                &:hover {
                    // @extend %primary-55;
                    @include color(neutral-10);
                }
                &:focus {
                    @extend %primary-85;
                }
                &:disabled {
                    @extend %neutral-40;
                }
            }
        }
        &-body,
        &-head {
            border-bottom: 0.1rem solid var(--neutral-20);
            border-radius: var(--space-4);
            @extend %d-none;
        }
        &-title {
            @extend %mb-4;
            @extend %title-50;
            @extend %neutral-0;
        }
        .card-name {
            .text {
                @include truncate-text(2);
            }
        }
    }
    .player-redirect {
        &:hover {
            .text {
                @extend %primary-55;
            }
        }
    }
}
.waf-ranking-horizontal {
    &.waf-standings {
        .table-footer a {
            border: .1rem solid var(--neutral-20);
            @extend %primary-50;
            &:hover {
                @extend %primary-55;
            }
        }
    }
    .layout-wrapper {
        background: var(--neutral-0);
        @include border(1, neutral-20);
    }
    .title {
        -webkit-line-clamp: inherit;
        @extend %neutral-100;
    }
    .table {
        &-title {
            @extend %neutral-100;
        }
        // &-footer {
        //     a {
        //         @extend %neutral-100;
        //     }
        // }
    }
    &.widget-layout-11 {
        .head-tab {
            a {
                border-color: var(--primary-50);
                @extend %bg-transparent;
                @extend %primary-50;
                &:hover {
                    border-color: var(--primary-55);
                    @extend %primary-55;
                }
                &:focus {
                    border-color: var(--primary-85);
                    @extend %primary-85;
                }
            }
        }
    }
}
.waf-paris {
    .head-logo {
        height: 9.2rem;
    }
}
.waf-ranking-redirect {
    .table {
        &-item:not(:last-child) {
            @extend %mb-10;
        }
        &-footer a {
            @include color(neutral-0);
        }
    }
}
.waf-paris,
.waf-world-championships,
.waf-arena,
.widget-layout-11 {
    .head-tab a {
        &:hover {
            border-color: var(--neutral-0);
            @extend %bg-neutral-70;
            @extend %neutral-0;
        }
        &:focus {
            border-color: var(--primary-85);
            @extend %neutral-0;
            @extend %bg-neutral-70;
        }
        &:disabled {
            border-color: var(--neutral-40);
            @extend %neutral-40;
            @extend %bg-neutral-70;
        }
    }
}
@include mq(col-tablet) {
    .widget-layout-11 {
        .table {
            margin-top: 0;
            &-wrapper {
                @include card-count(2, var(--space-4), wrap);
                .table {
                    margin-top: 0;
                }
            }
            &-footer {
                a {
                    text-transform: unset;
                }
            }
        }
        .waf-body,
        .head-wrap {
            @include large-wrapper();
        }
        .head-tab {
            position: unset;
        }
    }
    .waf-ranking-redirect {
        .table {
            &-footer {
                a {
                    text-transform: capitalize;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .widget-layout-11 {
        &.waf-world-championships,
        &.waf-paris {
            .layout-wrapper {
                max-height: 47.4rem;
            }
        }
        &.waf-component {
            margin-bottom: 25.4rem;
        }
        &.waf-paris {
            &:not(.waf-ranking-redirect) {
                margin-bottom: 31.4rem;
            }
            .head-wrap {
                padding-bottom: var(--space-16);
            }
            .table-footer a {
                font-size: 1.4rem;
            }
        }
        .layout-wrapper {
            padding: var(--space-12);
            max-height: 51.4rem;
        }
        .waf-head {
            width: 70%;
        }
        .waf-body,
        .head-wrap {
            @include default-wrapper();
        }
        .head {
            &-wrap {
                padding-bottom: var(--space-16);
            }
            &-logo {
                text-align: left;
                margin-inline: unset;
            }
        }
        .title {
            font-size: 4rem;
        }
        .event-title {
            font-size: 4rem;
        }
        .table {
            &-wrapper {
                justify-content: flex-end;
                @include card-count(3, var(--space-4));
            }
            &-row {
                min-height: 8.1rem;
            }
            &-body {
                display: block;
                border-radius: 1.6rem;
                overflow: hidden;
            }
            &-title {
                font-size: 2.4rem;
            }
            &-footer {
                width: max-content;
                // a {
                //     @include color(primary-50);
                // }
            }
        }
        .accordion-header {
            .btn-text {
                font-size: 2.4rem;
                font-weight: 700;
            }
        }
    }
    .waf-ranking-horizontal {
        &.waf-standings {
            .table-footer a {
                border: .1rem solid transparent;
            }
        }
        .layout-wrapper {
            display: grid;
            gap: 6.4rem;
        }
        .title {
            font-size: 3.2rem;
            line-height: 1.3;
            -webkit-line-clamp: inherit;
            width: 88%;
        }
        .table {
            &-body {
                border-top: 0.1rem solid var(--neutral-20);
            }
            &-footer {
                a {
                    background: transparent;
                }
            }
            &-item {
                width: calc(50% - 1*(var(--space-4))/2);
            }
        }
    }
    .waf-ranking-redirect {
        // &.waf-europe {
        //     .table-footer {
        //         flex-direction: row;
        //     }
        // }
        .junior-ranking {
            .table-footer {
                flex-direction: row;
            }
        }
        .table {
            &-wrapper {
                flex-direction: column;
                gap: var(--space-10);
            }
            &-item:not(:last-child) {
                margin-bottom: 0;
            }
            &-item,
            &-footer {
                width: 100%;
            }
            &-footer {
                flex-direction: column;
                a {
                    height: 9.6rem;
                    align-items: flex-end;
                    font-size: 2rem;
                }
            }
        }
        .layout-wrapper {
            position: relative;
            gap: 4rem;
            max-height: max-content;
        }
    }
    .waf-ranking-horizontal,
    .waf-ranking-redirect {
        &.waf-component {
            margin-bottom: var(--space-16);
        }
        .waf-head {
            width: unset;
        }
        .head-wrap {
            justify-content: flex-start;
        }
        .waf-body {
            padding-bottom: 0;
        }
    }
    .waf-paris {
        .head-wrap {
            padding-bottom: 0;
        }
        .head-logo {
            height: 14rem;
        }
        .table-footer a {
            font-size: 2rem;
        }
    }
    .waf-world-championships, .ranking-with-bg {
        .table-footer a {
            color: var(--primary-50);
            &:hover {
                color: var(--primary-55);
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-ranking-redirect {
        .layout-wrapper {
            display: grid;
            grid-template-columns: 57rem calc(100% - 61rem);
        }
    }
    .waf-ranking-horizontal {
        .layout-wrapper {
            grid-template-columns: 33rem calc(100% - 39.4rem);
        }
    }
}
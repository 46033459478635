@use "../config/" as *;

.waf-listing {
    &.waf-component{
        @extend %py-0;
        @extend %mb-20;
    }
    .head-wrap{
        margin-bottom: var(--space-0);
    }
    .title{
        @extend %mb-10;
        &::before{
            color: var(--neutral-40);
        }
    }
    &.waf-overlay-list{
        .article-list{
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - 4.8rem);
            grid-template-columns: unset;
        }
        .title{
            @include title-with-icon(shape, play, 24, null);
        }
    }
    &.waf-horizontal-list{
        .title{
            @include title-with-icon(shape, megaphone-fill, 24, null);
        }
    }
}

@include mq(col-tablet){
    .waf-listing {
        &.waf-overlay-list {
            .article-list {
                grid-auto-columns: calc(100% - 36rem);
            }
        }
    }
}
@include mq(col-desktop){
    .waf-listing{
        &.waf-overlay-list{
            .article-list{
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}
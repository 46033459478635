@use "../config/" as *;
.widget-layout-11 {
    .layout-wrapper {
        // background: transparent;
        padding: var(--space-8) var(--space-4);
        @include border(1, neutral-20);
        @extend %mb-20;
    }
    .title {
        display: block;
        font-size: 2.4rem;
        line-height: 1.4;
        @extend %mb-10;
    }
    .table {
        .card {
            &-name {
                max-width: calc(100% - 2.2rem);
                .text {
                    @include truncate-text(2);
                }
            }
        }
        &-item {
            &:not(:last-child) {
                @extend %mb-6;
            }
        }
        &-row {
            &:first-child {
                border-radius: 1.6rem 1.6rem 0 0;
            }
            .card-thumbnail {
                @extend %d-none;
            }
            .card-logo {
                flex-shrink: 0;
            }
        }
        &-body {
            @extend %d-block;
            border-block: 0.1rem solid var(--neutral-20);
        }
        &-footer {
            a {
                justify-content: center;
            }
            > a {
                // color: var(--primary-50);
                background-color: transparent;
            }
        }
    }
}
@include mq(col-tablet) {
    .widget-layout-11 {
        .layout-wrapper {
            padding: var(--space-12);
        }
        .waf-body {
            padding-bottom: 0;
        }
        .title {
            font-size: 3.2rem;
            // line-height: 1.2;
        }
        .table {
            &-item:not(:last-child) {
                margin-bottom: 0;
            }
            &-row {
                min-height: 8.1rem;
                .card-thumbnail {
                    display: block;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .widget-layout-11 {
        .head-wrap {
            padding-bottom: 0;
        }
    }
}
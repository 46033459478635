@use "../config" as *;
.waf-event-hero {
  margin-inline: var(--space-2-neg);
  border-radius: 0 0 1.6rem 1.6rem;
  @extend %hidden;
  &.waf-component {
    @extend %p-0;
  }
  .layout-wrapper {
    max-width: unset;
  }
  .card-banner {
    @extend %relative;
    @extend %w-100;
    @extend %common-gradient;
  }
  .card-wrap {
    @include default-wrapper();
    @extend %h-100;
    @extend %pb-2;
    @extend %flex-column-fe-n;
    @extend %relative;
    @extend %zindex-content;
  }
  .head-tab {
    a {
      width: max-content;
      border-color: var(--neutral-0);
      justify-content: space-between;
      @extend %btn-outline;
      @extend %neutral-0;
      @extend %gap-2;
      &:after {
        @include icon(arrow-down);
      }
      &:hover {
        border-color: var(--neutral-0);
        @extend %bg-neutral-70;
        @extend %neutral-0;
      }
      &:focus {
        border-color: var(--primary-85);
        @extend %neutral-0;
        @extend %bg-neutral-70;
      }
      &:disabled {
        border-color: var(--neutral-40);
        @extend %neutral-40;
        @extend %bg-neutral-70;
      }
    }
  }
  .title {
    @extend %neutral-0;
    @extend %title-30;
  }
  .desc {
    @extend %neutral-5;
  }
  img {
    border-radius: 0 0 1.6rem 1.6rem;
    object-fit: cover;
    @include position-combo(inset);
    @extend %h-100;
  }
}
.waf-arena-hero {
  margin-bottom: 0;
  .card {
    &-banner {
      height: 50rem;
      aspect-ratio: .795;
      &::after {
        content: unset;
      }
    }
    &-wrap {
      padding-bottom: var(--space-14);
    }
  }
  .title {
    margin-bottom: 0;
    @include truncate-text(4);
  }
}
@include mq(col-tablet) {
  .waf-event-hero {
    border-radius: 0 0 3.2rem 3.2rem;
    .title {
      font-size: 5.6rem;
    }
    img {
      border-radius: 0 0 3.2rem 3.2rem;
    }
  }
  .waf-arena-hero {
    margin-bottom: var(--space-20);
    .card {
      &-banner {
        max-width: 100%;
        height: 60rem;
        // min-height: 55rem;
        aspect-ratio: 2.4;
        &::after {
          content: "";
          height: 78.5%;
          background: linear-gradient(0deg, hsl(var(--hsl-neutral-100) / .8) 37.6%, hsl(var(--hsl-neutral-100) / 0) 100%);
        }
      }
      &-wrap {
        padding-bottom: var(--space-24);
      }
    }
    .title {
      width: 67%;
      -webkit-line-clamp: 3;
    }
  }
}
@use '../config/' as *;
.waf-sports-hero {
    .head-tab {
        a::after {
            @include icon(chevron-down);
        }
    }
    .title {
        @extend %mb-6;
    }
    .desc {
        font-weight: 700;
        max-width: 81rem;
        @extend %neutral-20;
        @extend %text-center;
    }
    .sports-desc {
        max-width: 56.3rem;
    }
    .card {
        &-banner {
            height: 50rem;
        }
        &-wrap {
            justify-content: center;
            align-items: center;
            padding-top: calc(var(--header-height) + 12.9rem);
            padding-bottom: 9.2rem;
        }
    }
    .sub-event {
        &-wrap {
            max-width: 41.4rem;
            @extend %w-100;
            @extend %my-10;
            &:has(.winter) {
                max-width: 34.4rem;
            }
        }
        &-list {
            justify-content: center;
            @include card-count(3, var(--space-4), wrap);
        }
        &-title {
            @extend %mb-4;
            @extend %text-center;
            @extend %neutral-0;
            @extend %title-60;
        }
        &-subtitle {
            @extend %body-s;
        }
        &-item {
            max-width: 12.8rem;
            flex-grow: 1;
            @extend %p-2;
            @extend %border-radius-m;
            @extend %bg-neutral-0;
            @extend %neutral-70;
            @extend %flex-column-sb-c;
            &.run {
                .sub-event-icon::after {
                    @include icon(run, 24);
                }
            }
            &.bike {
                .sub-event-icon::after {
                    @include icon(bike, 24);
                }
            }
            &.swim {
                .sub-event-icon::after {
                    @include icon(swim, 24);
                }
            }
            &.hill {
                .sub-event-icon::after {
                    @include icon(sports, 24);
                }
            }
            &.winter {
                width: auto;
                max-width: unset;
                @extend %p-4;
                .sub-event-title {
                    @extend %body-mb;
                    @extend %neutral-70;
                    @extend %mb-1;
                }
            }
        }
        &-icon {
            &::after {
                color: inherit;
            }
        }
    }
    .sub-item-title {
        color: inherit;
        @extend %text-m;
    }
}
@include mq(col-tablet) {
    .waf-sports-hero {
        .card {
            &-banner {
                height: 80rem;
            }
            &-wrap {
                max-width: 117rem;
                padding-top: calc(var(--header-redirect-height) + var(--header-height) + 3.1rem);
                padding-bottom: 18.6rem;
            }
        }
        .title {
            margin-bottom: var(--space-6);
        }
        .desc {
            font-size: 2.4rem;
        }
        .sub-event {
            &-wrap {
                margin-block: var(--space-6);
            }
            &-title {
                font-size: 2rem;
            }
            &-subtitle {
                font-size: 1.6rem;
            }
            &-item {
                padding: var(--space-4);
                &.run,
                &.bike,
                &.swim,
                &.hill {
                    .sub-event-icon::after {
                        font-size: 3.2rem;
                    }
                }
                &.winter {
                    padding: var(--space-6);
                    .sub-event-title {
                        font-size: 2rem;
                        line-height: 1.4;
                    }
                }
            }
        }
        .sub-item-title {
            font-size: 1.6rem;
        }
    }
}